import React, { useEffect, useState } from "react";
import { axiosGraphQLQuery, getSession } from "src/components/Auth/AuthHandler";
import {
    Container, AppBar, Toolbar, Typography, Grid, Card, CardContent, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button,
    Alert,
    IconButton,
    Collapse,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    RadioGroup,
    FormControlLabel,
    Radio,
    DialogActions,
    CircularProgress
} from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import {
    CategoryScale,
    LinearScale,
    BarElement,
    Title
} from 'chart.js';
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title);

const glassEffectStyle = {
    backgroundColor: '#08316A',  // Adjust opacity for desired transparency
    backdropFilter: 'blur(4px)',
    borderRadius: '4px',
    padding: '10px',
    color: "white",
    border: '1px solid rgba(255, 255, 255, 0.3)'  // Optional border for more definition
};

const flexContainerStyle = {
    display: 'flex',
    flexDirection: 'column'
};

const flexItemStyle = {
    flexGrow: 1
};

const StatisticsCard = ({ template, answerlist, eventsloading }) => {

    const [chartData, setChartData] = useState()
    const [barchartData, setBarChartData] = useState()
    const [barchartData1, setBarChartData1] = useState()

    const extractSubgraphAnswers = (template, answers) => {
        const results = {};

        template.stepper.forEach(step => {
            step.content.forEach(item => {
                if (item.type === "SUBGRAPH") {
                    if (!results[item.id]) {
                        results[item.id] = {
                            label: item.label,
                            options: item.options,
                            counts: new Array(item.options.length).fill(0)
                        };
                    }

                    answers.forEach(answer => {
                        if (answer[item.id] !== undefined) {
                            results[item.id].counts[answer[item.id]]++;
                        }
                    });
                }
            });
        });

        return Object.values(results);
    };
    const processAndSplitAnswers = (template, answers) => {
        const results = {};

        template.stepper.forEach(step => {
            step.content.forEach(item => {
                if (item.type === "SUBGRAPH") {
                    // Initialize results storage for this item if it hasn't been initialized
                    if (!results[item.id]) {
                        results[item.id] = {
                            label: item.label,
                            options: item.options.map(option => option.split(" - ")[0].trim()),
                            counts: {}
                        };

                        // Initialize counts for each split option to zero
                        results[item.id].options.forEach(option => {
                            results[item.id].counts[option] = 0;
                        });
                    }

                    // Count occurrences of each split option
                    answers.forEach(answer => {
                        const answerIndex = answer[item.id];
                        if (answerIndex !== undefined) {
                            try {
                                var itemoptions = item.options[parseInt(answerIndex)]
                                if (itemoptions) {
                                    const selectedOption = itemoptions?.split(" - ")[0]?.trim();

                                    results[item.id].counts[selectedOption]++;
                                }
                            }
                            catch (error) {
                                console.log(error)
                            }
                        }
                    });
                }
            });
        });

        return Object.values(results);
    };
    const processAndSplitAnswersRightSide = (template, answers) => {
        const results = {};

        template.stepper.forEach(step => {
            step.content.forEach(item => {
                if (item.type === "SUBGRAPH") {
                    // Initialize results storage for this item if it hasn't been initialized
                    if (!results[item.id]) {
                        results[item.id] = {
                            label: item.label,
                            options: item.options.map(option => {
                                const parts = option.split(" - ");
                                return parts.length > 1 ? parts[1].trim() : 'Unknown';
                            }),
                            counts: {}
                        };

                        // Initialize counts for each split option to zero
                        results[item.id].options.forEach(option => {
                            results[item.id].counts[option] = 0;
                        });
                    }

                    // Count occurrences of each split option
                    answers.forEach(answer => {
                        const answerIndex = answer[item.id];
                        if (answerIndex !== undefined) {
                            const selectedOption = item.options[parseInt(answerIndex)];
                            if (selectedOption) {
                                const parts = selectedOption.split(" - ");
                                const rightPart = parts.length > 1 ? parts[1].trim() : 'Unknown';
                                results[item.id].counts[rightPart]++;
                            }
                        }
                    });
                }
            });
        });

        return Object.values(results);
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },

    };

    function wrapLabel(text, maxLineWidth) {
        const words = text.split(' ');
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            if (currentLine.length + words[i].length + 1 <= maxLineWidth) {
                currentLine += ' ' + words[i];
            } else {
                lines.push(currentLine);
                currentLine = words[i];
            }
        }
        lines.push(currentLine);
        return lines;
    }
    const shortenLabels = (labels, maxLineWidth = 20) => {
        return labels.map(label => wrapLabel(label, maxLineWidth));
    };

    useEffect(() => {
        if (template && answerlist.length > 0) {
            const subgraphData = extractSubgraphAnswers(template, answerlist)[0];
            const bargraphData = processAndSplitAnswers(template, answerlist)[0];
            const subgraphDataRightSide = processAndSplitAnswersRightSide(template, answerlist)[0];

            const tempchartData = {
                labels: subgraphData.options,
                datasets: [{
                    data: subgraphData.counts,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        '#FF6384',
                        'rgba(54, 162, 235, 0.2)',
                        '#36A2EB',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        '#FF6384',
                        '#36A2EB',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                }]
            };

            const tempbarchartData = {
                labels: [...new Set(bargraphData.options)],
                datasets: [{
                    label: "Verteilung",
                    data: bargraphData.counts,
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
                }]
            };


            var shla = shortenLabels([...new Set(subgraphDataRightSide.options)])
            const tempbarchartData1 = {
                labels: shla,
                datasets: [{
                    data: Object.values(subgraphDataRightSide.counts),

                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                }]
            };

            setBarChartData(tempbarchartData)
            setBarChartData1(tempbarchartData1)

            setChartData(tempchartData)


        }


    }, [template, answerlist])


    return <Card raised style={flexItemStyle}>
        <CardContent>
            <Typography variant="h6" gutterBottom style={glassEffectStyle}>Auswertungen</Typography>
            <Grid container justifyContent="center">
                <Grid item xs={4} >{chartData ? <Pie data={chartData} /> : <></>}</Grid>
                <Grid item xs={6} container alignItems="center" direction="column">
                    <Grid item xs={6} >
                        {barchartData ? <Bar options={options} data={barchartData} /> : <></>}
                    </Grid>
                    <Grid item xs={6} >
                        {barchartData1 ? <Bar options={options} data={barchartData1} /> : <></>}
                    </Grid>
                </Grid>

                {eventsloading && <Grid>
                    <Typography>Statistik wird generiert. Bitte warten.</Typography>
                    <CircularProgress color="secondary" />
                </Grid>}
            </Grid>
        </CardContent>
    </Card>
};

const GMStats = () => {
    const [templateList, setTemplateList] = useState([])
    const [departments, setDepartments] = useState()
    const [reports, setReports] = useState([])
    const [userID, setUserID] = useState(getSession().userID + "")
    const [userEvents, setUserEvents] = useState([])
    const [userlevel, setUserlevel] = useState()
    const [eventsloading, setEventLoading] = useState(true)
    const [reportLoading, setReportingLoading] = useState(true)
    const [gsmasterdata, setGsmasterData] = useState()

    useEffect(() => {
        reloadFunction()
    }, [])


    const reloadFunction = async () => {

        setEventLoading(true)
        var query = `
        {
            gmdepartments {
              gmdid
              gmdname
              gmdtemplateid
              gmdleaders
              gmdmembers
            }
          }
        `
        var loadquery =
            `{
            gmtemplates{
            tid
            tname
            tcontent
            }
        }`

        axiosGraphQLQuery(query, null, false).then(x => {
            if (x.data.data.gmdepartments) {
                var loadeddepartmentslist = x.data.data.gmdepartments.map(x => {
                    return {
                        id: x.gmdid,
                        name: x.gmdname,
                        templateId: x.gmdtemplateid,
                        leaders: JSON.parse(x.gmdleaders.replaceAll("`", "\"")),
                        members: JSON.parse(x.gmdmembers.replaceAll("`", "\""))
                    }
                })

                var isLeader = false
                var isMember = false
                loadeddepartmentslist = loadeddepartmentslist.find(dep => {
                    isLeader = dep.leaders.includes(userID)
                    isMember = dep.members.includes(userID)

                    isLeader ? setUserlevel("leader") : setUserlevel("member")

                    return isLeader || isMember
                })
                setDepartments(loadeddepartmentslist)
                if (loadeddepartmentslist) {

                    var loadgmeventlogquery =
                        `{
                    gmeventlog
                    (
                      where:[
                           {connective:BLANK,column:gmeventlog_gmedepartmentid,operator:EQUAL,value:"${loadeddepartmentslist.id}"}
                       
                      ]
                    ) 
                    {
                      gmeid
                      gmeuserid
                      gmeusername
                      gmeuserlevel
                      gmedepartmentid
                      gmetemplate
                      gmeanswers
                      gmestatus
                      gmecreated
                      dynamic_snapshot_timestamp
                      totalrowcount
                    
                    }
                  }`

                    axiosGraphQLQuery(loadgmeventlogquery, null, false).then(x => {
                        if (x.data.data.gmeventlog) {
                            setUserEvents(x.data.data.gmeventlog.sort((a, b) => {
                                const dateA = new Date(a.dynamic_snapshot_timestamp);
                                const dateB = new Date(b.dynamic_snapshot_timestamp);
                                return dateB - dateA;
                            }))
                        }
                    }).then(lod => { setEventLoading(false) }).catch(err => { setEventLoading(false) })

                    axiosGraphQLQuery(loadquery, null, false).then(x => {
                        if (x.data.data.gmtemplates) setTemplateList(x.data.data.gmtemplates.map(x => {
                            x.tcontent = JSON.parse(atob(x.tcontent))
                            return x
                        }).find(temp => temp.tid == loadeddepartmentslist.templateId))
                    }).catch(x => { })
                } else {
                }
            }
        }).catch(err => setEventLoading(false))
    }

    return <div>
        {departments &&
            <Grid style={{ width: "85vw" }}>
                <StatisticsCard eventsloading={eventsloading} template={templateList?.tcontent} answerlist={userEvents.map(evt => JSON.parse(atob(evt.gmeanswers)))} />
            </Grid>
        }


    </div>

}

export default GMStats